.our-success-card-holder{
    display: flex;
    flex-wrap: wrap;
}
.our-success-numbers-card{
    width: 20rem;
    margin: 0.6rem;
    /* background-color: red; */
    text-align: center;
    margin-bottom: 3.5rem;
}
.our-s-number{
    font-size: 3.2rem;
    font-weight: 500;
    margin-bottom: 1rem;
}
.our-s-name{
    font-size: 1rem;
}