.one-view{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3rem 0 ;
}

.one-view .text-data{
    padding: 0.7rem;
}
.one-view-sub-p{
    font-size: 1.1rem;
    line-height: 1.5rem;
    /* color: rgb(24, 23, 23); */
}

.one-view-sub h4{
    font-size: 1.4rem;
    font-weight: 500;
    text-decoration: underline;
    margin-bottom: 1rem;
}
.one-view-sub{
    margin: 1.2rem;
}
