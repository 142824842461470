.company-text-box {
    max-width: 60%;
    /*text-align: center;*/
  }
  .comp-text {
    margin: 1rem 0;
  }
  .titleDesc{
      font-weight: 400;
      margin-bottom: 1rem;
      text-align: justify;
  }