.bg-home {
  background-image: url("https://res.cloudinary.com/learnhat/image/upload/v1646900652/orion-new-website/Banner_copy_2x_w9dg67.png");
  height: 95vh;
}
.bg-home1 {
  background-image: url("https://res.cloudinary.com/learnhat/image/upload/v1647413076/orion-new-website/german-nursing-p/Banner_-_3_copy_2x_s6q1dj.png");
  height: 95vh;
}
.bg-home2 {
  background-image: url("https://res.cloudinary.com/learnhat/image/upload/v1647415444/orion-new-website/german-nursing-p/Banner_-_2_copy_2x_yegjp2.png");
  height: 95vh;
}
/* //carousel */
.carousel.carousel-slider {
  margin-bottom: -3rem;
}

.carousel-root {
  margin-bottom: -3rem;
}

@media (max-width: 500px) {
  .bg-home {
    height: 35vh;
  }
}
.main-href {
  text-decoration: none;
}

.home {
  width: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-data-cont {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.home-data-cont-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.home-data-subcont-right {
  color: white;
  width: 45%;
  text-align: left;
  padding: 5rem;
}
.hero-title-1 {
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: 500;
}
.hero-title-2 {
  font-size: 3.5rem;
  line-height: 3.8rem;
  font-weight: 500;
}
.hero-title-extra {
  font-weight: 500;
  font-size: 2.5rem;
  margin: 1rem 0;
}
.inner-cont {
  margin-bottom: 2.5rem;
}
@media (max-width: 1100px) {
  .home-data-subcont-right {
    padding: 2.5rem;
  }
  .hero-title-1 {
    font-size: 1.7rem;
    line-height: 2.1rem;
    font-weight: 500;
  }
  .hero-title-2 {
    font-size: 3.2rem;
    line-height: 3.5rem;
    font-weight: 500;
  }
}
@media (max-width: 500px) {
  .home {
    height: 40vh;
    /* background-size: 100% 100%; */
    background-repeat: no-repeat;
    background-position: 0% 20%;
    background-position: center;
  }
  .home-data-subcont-right {
    padding: 0.4rem;
    width: 45%;
  }
  .hero-title-1 {
    font-size: 0.9rem;
    line-height: 1rem;
    font-weight: 500;
  }
  .hero-title-2 {
    font-size: 1.2rem;
    line-height: 1.5rem;
    font-weight: 500;
  }
  .hero-title-extra {
    font-weight: 500;
    font-size: 0.9rem;
    margin: 0.5rem 0;
  }
  .inner-cont {
    margin: 1rem;
  }
}
@media screen and (min-width: 500px) and (max-width: 850px) {
  .home {
    height: 65vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .home-data-subcont-right {
    padding: 2rem;
    width: 38%;
  }
  .hero-title-1 {
    font-size: 1.5rem;
    line-height: 1.9rem;
    font-weight: 500;
  }
  .hero-title-2 {
    font-size: 2.6rem;
    line-height: 3.3rem;
    font-weight: 500;
  }
}
